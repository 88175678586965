import { Col, Form, message, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom, loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import {
    BasicValueModel,
    DocumentCategory,
    DocumentModel,
    MachineDocumentViewModel,
    MachineRentalTypeModel,
    MachineSaleCommissionViewModel,
    MachineSaleDepositFeeViewModel,
} from "../../../service";
import { useNavigate } from "react-router-dom";
import SaleMachineInfoPart from "../../../page-parts/sale-machine-info-part";
import SaleMachineAdditionalInfoPart from "../../../page-parts/sale-machine-additional-info-part";
import SaleMachinePricePart from "../../../page-parts/sale-machine-price-part";
import ListContainer from "../../../components/containers/list-container";

const AddSaleMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doPostCreateMachine, doGetMachineCommissionsForSale, doGetMachineCommissionsForFee, doGetExchangeRates } = useSecureService();
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const navigate = useNavigate();
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [loading] = useAtom(loadingAtom);
    const [fees, setFees] = useState<MachineSaleDepositFeeViewModel[] | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<{ catid: Number; subCatid: Number } | undefined>(undefined);
    const [commissions, setCommissions] = useState<MachineSaleCommissionViewModel[] | undefined>(undefined);
    const [exchangeRates, setExchangeRates] = useState<BasicValueModel[] | undefined>(undefined);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceCurrency, setPriceCurrency] = useState("");

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        editMachine((mach) => {
            return {
                ...mach,
                lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                id: undefined,
            };
        });
        const getCommissionsAndFees = async () => {
            setFees(await doGetMachineCommissionsForFee());
            setCommissions(await doGetMachineCommissionsForSale());
            setExchangeRates(await doGetExchangeRates());
        };
        getCommissionsAndFees();
        //eslint-disable-next-line
    }, []);

    const calculatedPrices: {
        depositFee: string;
        commissionPrice: string;
        total: string;
    } = useMemo(() => {
        var commissionRate = commissions?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.priceRate || 0;
        var exchangeRate = exchangeRates?.find((x) => x.name === priceCurrency)?.value || 1;
        var commission = (commissionRate / 100) * totalPrice * exchangeRate;
        var totalPriceTL = totalPrice * exchangeRate;
        var netPriceTL = totalPriceTL - commission;

        return {
            depositFee: (fees?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.depositFee || 0).toString() + " TL",
            commissionPrice: commission.toFixed(2).toString() + " TL",
            total: netPriceTL.toFixed(2).toString() + " TL",
        };
    }, [fees, commissions, selectedSubcategory, priceCurrency, totalPrice, exchangeRates]);

    const checkFields = async (): Promise<boolean> => {
        var rentalTitleCheck = await form.getFieldValue(['saleAdTitle']);
        console.log('heree:' , rentalTitleCheck);
        if (!rentalTitleCheck || rentalTitleCheck === ""){
            message.error(t("machines.add.AdLabel-error"));
            return false
        }

        return true;
    }

    const doCreateSaleMachine = async (params: any) => {
        if (editedMachine) {
            const result = await doPostCreateMachine(
                { ...params, lessorCompanyId: companyId ? parseInt(companyId) : undefined, documents: docs, isMachineForSale: true },
                1
            );
            if (result) {
                setMessage({
                    message: t("machines.add.successCreate"),
                    type: "success",
                });
                navigate("/sale/machines");
            }
        }
    };
    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];
        if ( !(await checkFields()) ) {
            return;
        }
        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                rentalTypes: rentalTypes,
                documents: docs,
            };
        });

        await doCreateSaleMachine(params);
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    return (
        <ListContainer>
            <Form name="add-machine" initialValues={editedMachine?.id === undefined ? editedMachine : undefined} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <SaleMachineInfoPart
                            uploadChange={uploadedFilesChanged}
                            docs={pics}
                            form={form}
                            subcategoryChanged={(catid, subcatid) => setSelectedSubcategory({ catid: catid, subCatid: subcatid })}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <SaleMachineAdditionalInfoPart />
                            </Col>
                            <Col md={24} xs={24}>
                                <SaleMachinePricePart
                                    onCurrencyChange={(val) => setPriceCurrency(val || "")}
                                    onPriceChange={(val) => setTotalPrice(val)}
                                    depositFee={calculatedPrices.depositFee}
                                    machineGoCommission={calculatedPrices.commissionPrice}
                                    totalPriceForMachineOwner={calculatedPrices.total}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" loading={loading} style={{ marginTop: "1rem" }}>
                                {t("common.save")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ListContainer>
    );
};

export default AddSaleMachine;
